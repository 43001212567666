import { v4 as uuidv4 } from "uuid"; // Add this line

export const coinbaseRedirectURL = encodeURIComponent(
  process.env.REACT_APP_COINBASE_CLIENT_REDIRECT_URL as string
);

// scopes
export const scopes = [
  "wallet:user:read",
  "wallet:addresses:read",
  "wallet:buys:read",
  "wallet:accounts:read",
  "wallet:deposits:read",
  "wallet:sells:read",
  "wallet:transactions:read",
  "wallet:withdrawals:read",
];
export const coinbaseScopes = scopes.join(",");

// CSRF
const csrfToken = uuidv4();
localStorage.setItem("csrfToken", csrfToken);
// console.log("[oauth] csrfToken", csrfToken);

export const coinbaseAuthURL = `https://www.coinbase.com/oauth/authorize?client_id=e5036ac5db0ac0b2f1a14f21128102193f6de1395b4023559a75866e91a8d933&redirect_uri=${coinbaseRedirectURL}&state=${csrfToken}&response_type=code&scope=${coinbaseScopes}&account=all`; // Use the CSRF token as the state parameter
