export const API = {
  validateReceipt: "/iap/validate-receipt",
  featureFlags: "/feature-flags",
};

/* endpoints that support pagination so can use usePaginatedSearch hook to search for data  */
export const PAGINATED_API = {
  /* returns a list of exchanges - used for searching for avaiable exhcanges and if no search returns all */
  getExchanges: "/exchanges/search",
};
