import { useQuery } from "@tanstack/react-query";
import { axios, authorizedAxios } from "@/axios";
import { useAuth } from "@moonscape/shared";
import { AuditType } from "@moonscape/shared";

/**
 * gets the list of transactions based on audit type i.e. missing-fiat, missing-deposit, missing-withdrawal, etc
 *
 * @param page
 * @param exchange
 * @param asset
 * @param isSearching
 * @param searchedValue
 * @param onLoading
 * @returns
 */
export const useAuditData = (
  page,
  exchange,
  asset,
  auditTypeKey, // audit type key i.e. missing-fiat, missing-deposit, missing-withdrawal, etc
  isSearching,
  searchedValue,
  onLoading
) => {
  const { user, isAuthenticated, isLoading, getToken } = useAuth();
  // userId
  const email = user?.email;

  // console.log("useAuditData-page", page);
  // console.log("[audit] auditType ", auditTypeKey);

  let exchangeQueryParam = "";
  let assetQueryParam = "";

  if (asset) assetQueryParam = `&asset=${asset}`;
  if (exchange) exchangeQueryParam = `&exchange=${exchange}`;

  const getAuditData = async () => {
    try {
      const token = await getToken();
      let url = isSearching
        ? // TODO: edit the search to handle auditTypeKey
          `/audit/search/?search=${searchedValue}&cursor=${page ?? 0}`
        : `/audit/${auditTypeKey}?cursor=${
            page ?? 0
          }${exchangeQueryParam}${assetQueryParam}`;

      console.log("[audit] useAuditData() url ", url);
      const res = await authorizedAxios(token).get(
        // TODO: add filter here for exchange and asset
        url
      );

      // console.log("/audit res", res.data, user?.email);
      return res.data;
    } catch (error) {
      console.log("[useAuditData] error ", error);
      return { error };
    }
  };

  // useQuery key to cache
  const useQueryKey = !isSearching
    ? `audit-${auditTypeKey}-${page}`
    : // ? `audit-${auditTypeKey}-${exchange}-${asset}-${page}`
      `audit-search-${searchedValue}`;
  const {
    isLoading: isQueryLoading,
    isSuccess,
    isIdle,
    error,
    isError,
    data: auditTransactions,
    isPreviousData,
    isFetching,
  } = useQuery({
    queryKey: [useQueryKey, page, auditTypeKey],
    queryFn: getAuditData,

    // The query will not execute until the userId exists and page is defined
    enabled: !!email,

    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  isSearching && isFetching ? onLoading(isFetching) : onLoading(false);

  return { isQueryLoading, isIdle, error, auditTransactions, isPreviousData };
};
