/**
 * Feature flag keys for use with useFeatureFlag hook
 */
export const FEATURE_FLAG_KEYS = {
  // User features
  MOBILE_SHOW_IAP: "mobile_show_iap",
  WEB_SHOW_IAP: "web_show_iap",
  WEB_SAFE_HARBOR: "web_safe_harbor",

  // // Premium features
  // ADVANCED_ANALYTICS: "advanced_analytics",
  // BULK_OPERATIONS: "bulk_operations",
  // CUSTOM_REPORTS: "custom_reports",

  // // Beta features
  // DEFI_TRACKING: "defi_tracking",
  // NFT_TRACKING: "nft_tracking",

  // // System features
  // DEBUG_MODE: "debug_mode",
  // PERFORMANCE_MONITORING: "performance_monitoring",

  // // Mobile specific features
  // BIOMETRIC_AUTH: "biometric_auth",
  // PUSH_NOTIFICATIONS: "push_notifications",

  // // Web specific features
  // EXPORT_DATA: "export_data",
  // BULK_IMPORT: "bulk_import",
} as const;

// Type for feature flag keys
export type FeatureFlagKey = keyof typeof FEATURE_FLAG_KEYS;
