import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Alert, AlertWithComponent, ButtonLink, Screen } from "@/components";
import { useTaxOperationStatus } from "@/hooks";
import { clsx } from "clsx";
import { Link } from "react-router-dom";

import {
  useTaxEventsPagination,
  useProfile,
  getCurrentTaxYearCGTRate,
  getCGTRateForCurrentYear,
  useCapitalGainsData,
  TaxJurisdiction,
  useSafeHarbor,
} from "@moonscape/shared";
import { useTriggerTaxReport } from "./useTriggerTaxReport";
import { SettingsTabs } from "../SettingsScreen/SettingsTabs";
import { Alert as OnboardingAlert } from "@/components/OnboardingAlert";

import { TaxEventsTable } from "./components/TaxEventsTable";
import { IndividualInformation, TaxSummary } from "./components";
import { FAQsUK, FAQsUS } from "./components/FAQ";

import {
  CURRENT_TAX_YEAR,
  TAX_YEARS,
  formatAmount,
  dayjs,
} from "@moonscape/shared";

export const TaxSafeHarborScreen = (props) => {
  let history = useHistory();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();

  // reminders
  const hasReminders = false;

  // for tax operation status
  const { operationStatus } = useTaxOperationStatus();
  const isTaxOperationRunning = operationStatus?.status === "running";
  const taxStartedTime = dayjs(operationStatus?.last_updated).fromNow();

  const [selectedTaxYear, setSelectedTaxYear] = useState(CURRENT_TAX_YEAR);

  // const tabs = [
  //   { name: "Capital gains", href: `${path}/cgt`, current: true },
  //   { name: "Income", href: `${path}/income`, current: false },
  //   { name: "Holdings", href: `${path}/holdings`, current: false },
  //   { name: "HMRC", href: `${path}/hmrc`, current: false },
  // ];

  // const [activeTab, setActiveTab] = useState(
  //   tabs.filter((tab) => tab.href === pathname)
  // );
  const { profile } = useProfile();
  const hasLinkedExchangeAccount = profile?.hasLinkExchangeAccounts;

  const [showReviewTxns, setShowReviewTxns] = useState(0);
  const [showStats, setShowStats] = useState(true);
  const [showAlert, setShowAlert] = useState(true);
  const { taxEvents } = useTaxEventsPagination({ taxYear: selectedTaxYear });
  const { recalculateTax } = useTriggerTaxReport();
  const hasTaxEvents = taxEvents?.items?.length > 0;

  const { cgt_report: cgt } = useCapitalGainsData({
    taxYear: CURRENT_TAX_YEAR,
  });

  // // safe harbor
  const {
    isQueryLoading,
    isIdle,
    error,
    safeHarbor,
    refetch,
    storeMutation,
    updateMutation,
    storeDetailMutation,
    updateDetailMutation,
  } = useSafeHarbor(2025);

  console.log("safeHarbor", safeHarbor);

  const hasMissingTaxInfo = !profile?.hasRequiredTaxInfo;

  // data to display - formatted or processed
  const currentCGTRate = getCurrentTaxYearCGTRate(profile)?.rate;
  const displayCGTRate = currentCGTRate ? `${currentCGTRate}%` : "-";
  console.log("currentCGTRate", currentCGTRate);

  const SafeHarborDetailRow = ({ detail }) => {
    return (
      <div className="flex flex-col lg:flex-row lg:space-x-4 py-2 border-b border-gray-200">
        <div className="flex-1">
          <p className="text-sm text-gray-500">{detail.tradeid}</p>
          <p className="text-xs text-gray-400">
            {dayjs(detail.datetime).utc().format("HH:mm:ss")}
          </p>
        </div>
        <div className="flex-1">
          <p className="text-sm text-gray-500">{detail.exchange}</p>
        </div>
        <div className="flex-1">
          <p className="text-sm text-gray-500">{detail.asset}</p>
        </div>
        <div className="flex-1">
          <p className="text-sm text-gray-500">{formatAmount(detail.qty)}</p>
        </div>
        <div className="flex-1">
          <p className="text-sm text-gray-500">{formatAmount(detail.cost)}</p>
        </div>
        <div className="flex-1">
          <p className="text-sm text-gray-500">
            {formatAmount(detail.total_cost)}
          </p>
        </div>
      </div>
    );
  };
  const SafeHarborDetailsTable = ({ details }) => {
    // Group details by date
    const groupedDetails = details.reduce((acc, detail) => {
      const date = dayjs(detail.datetime).utc().format("MMM D YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(detail);
      return acc;
    }, {});

    return (
      <div className="lg:col-span-2 px-2">
        <div className="flex flex-col lg:flex-row lg:space-x-4 py-2 border-b border-gray-200 bg-gray-100">
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Trade ID</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Time</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Exchange</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Asset</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Quantity</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Cost</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Total Cost</p>
          </div>
        </div>
        {Object.keys(groupedDetails).map((date) => (
          <div key={date}>
            <div className="bg-gray-100 py-2">
              <h3 className="text-sm font-medium text-gray-700">{date}</h3>
            </div>
            {groupedDetails[date].map((detail) => (
              <SafeHarborDetailRow key={detail.id} detail={detail} />
            ))}
          </div>
        ))}
      </div>
    );
  };

  const AggregatedSafeHarborDetails = ({ details }) => {
    // Aggregate data by exchange and asset
    const aggregatedData = details.reduce((acc, detail) => {
      const key = `${detail.exchange}-${detail.asset}`;
      if (!acc[key]) {
        acc[key] = {
          exchange: detail.exchange,
          asset: detail.asset,
          qty: 0,
          cost: 0,
          total_cost: 0,
        };
      }
      acc[key].qty += detail.qty;
      acc[key].cost += detail.cost;
      acc[key].total_cost += detail.total_cost;
      return acc;
    }, {});

    const aggregatedArray = Object.values(aggregatedData);

    return (
      <div className="lg:col-span-2">
        <div className="flex flex-col lg:flex-row lg:space-x-4 py-2 border-b border-gray-200 bg-gray-100">
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Exchange</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Asset</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Quantity</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Cost</p>
          </div>
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-700">Total Cost</p>
          </div>
        </div>
        {aggregatedArray.map((item, index) => (
          <div
            key={index}
            className="flex flex-col lg:flex-row lg:space-x-4 py-2 border-b border-gray-200"
          >
            <div className="flex-1">
              <p className="text-sm text-gray-500">{item.exchange}</p>
            </div>
            <div className="flex-1">
              <p className="text-sm text-gray-500">{item.asset}</p>
            </div>
            <div className="flex-1">
              <p className="text-sm text-gray-500">{formatAmount(item.qty)}</p>
            </div>
            <div className="flex-1">
              <p className="text-sm text-gray-500">{formatAmount(item.cost)}</p>
            </div>
            <div className="flex-1">
              <p className="text-sm text-gray-500">
                {formatAmount(item.total_cost)}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Screen heading="Tax - Safe Harbor" className="bg-gray-50">
      <main className="mx-auto p-8">
        {!hasLinkedExchangeAccount && <OnboardingAlert />}

        {isTaxOperationRunning && (
          <AlertWithComponent>
            <p>
              Calculating tax report for {selectedTaxYear} in progress. Started{" "}
              {taxStartedTime}.
            </p>
          </AlertWithComponent>
        )}

        <div className="px-4 sm:px-6 lg:px-8">
          <div className=" flex-row bg-white p-6 px-8 rounded-lg ">
            <h2 className="text-xl font-bold text-gray-900">
              IRS Safe Harbor Transitional Relief
            </h2>
            <p className="text-gray-700 w-2/3">
              The IRS has introduced transitional relief through Revenue
              Procedure 2024-28, giving crypto holders a chance to reorganize
              unused cost basis for digital assets by January 1, 2025.
              <br />
              <br />
              This is your window to prepare for the upcoming wallet-specific
              reporting rules.
              <br />
              <br />
              We'll be adding to this page with more information and tools to
              help you navigate the new requirements.
            </p>
          </div>
          {/* <p className="text-grey-700">April 5 2022 - 5 April 2023</p> */}

          {/* <SettingsTabs pathname={pathname} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} /> */}

          {/* main content with blocks */}

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-6">
            <div className="flex flex-col space-y-4 lg:col-span-2 ">
              {hasMissingTaxInfo && (
                <div className="bg-white p-6 rounded-lg ">
                  <h2 className="text-lg font-medium mb-6">Reminders</h2>
                  <Alert variant="info">
                    <p>
                      <Link to={`/settings/tax`}>
                        Click here to complete your Tax Settings for tax
                        calculations
                      </Link>
                    </p>
                  </Alert>
                  {/* <Alert variant="info">
                    <p>
                      You have not reviewed your transactions for the selected
                      tax year.{" "}
                    </p>
                  </Alert> */}
                </div>
              )}
              {hasReminders && (
                <div className="bg-white p-6 rounded-lg ">
                  <h2 className="text-lg font-medium mb-6">Reminders</h2>
                  <Alert variant="warning">
                    <p>
                      You have not reviewed your transactions for the selected
                      tax year.{" "}
                      <a
                        href="/review-transactions"
                        style={{ textDecoration: "underline" }}
                      >
                        Review now
                      </a>
                    </p>
                  </Alert>
                  <Alert variant="info">
                    <p>
                      You have not reviewed your transactions for the selected
                      tax year.{" "}
                    </p>
                  </Alert>
                </div>
              )}

              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-lg font-medium">
                  Aggregated Safe Harbor Details
                </h2>
                {safeHarbor && safeHarbor.safe_harbor_details.length > 0 ? (
                  <AggregatedSafeHarborDetails
                    details={safeHarbor.safe_harbor_details}
                  />
                ) : (
                  <p>No safe harbor details available.</p>
                )}
              </div>

              <div className="bg-white p-6 rounded-lg  ">
                <h2 className="text-lg font-medium">
                  Safe Harbor Transactions
                </h2>
                <p className="text-gray-700 w-2/3">
                  This list is your transactions that we've identified as
                  eligible for the safe harbor relief.
                </p>
                {/* TODO: insert stats about unused cost-basis */}

                {safeHarbor && safeHarbor.safe_harbor_details.length > 0 ? (
                  <SafeHarborDetailsTable
                    details={safeHarbor.safe_harbor_details}
                  />
                ) : (
                  <p>No safe harbor details available.</p>
                )}
              </div>

              <div className="bg-white p-6 rounded-lg  ">
                <h2 className="text-lg font-medium">Tax Events</h2>
                <TaxEventsTable taxYear={selectedTaxYear} />
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <div className="flex flex-col space-y-4">
                <div className="bg-white p-6 rounded-lg min-w-80">
                  <h2 className="text-lg font-medium text-gray-900 mb-4">
                    New IRS Rules for 2025: <br />
                  </h2>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    What You Need to Know
                  </h3>
                  <h4 className="text-md font-medium text-gray-900 mb-2">
                    Key Changes
                  </h4>
                  <ul className=" list-inside text-sm text-gray-500 mb-6">
                    <li className="mb-4">
                      <strong className="mb-4">
                        Wallet-by-Wallet Tracking:
                      </strong>
                      <br /> Starting January 1, 2025, you must calculate your
                      crypto cost basis separately for each wallet or account.
                    </li>
                    <li className="mb-4">
                      <strong className="mb-4">
                        No More Universal Pooling:
                      </strong>
                      <br /> The old method of treating all assets as a single
                      pool will no longer be allowed.
                    </li>
                  </ul>
                  <h3 className="text-md font-medium text-gray-900 mb-2">
                    Transition Relief <br /> (Rev. Proc. 2024-28)
                  </h3>
                  <ul className="  text-sm text-gray-500 mb-4">
                    <li className="font-bold mb-2">
                      Deadline: Allocate cost basis for each wallet by January
                      1, 2025.
                    </li>
                    <li>
                      Options:
                      <ul className="list-disc  ml-4">
                        <li>
                          Global Allocation: Distribute cost basis evenly across
                          all assets.
                        </li>
                        <li>
                          Specific Unit Allocation: Assign basis values to
                          individual assets strategically.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h3 className="text-md font-medium text-gray-900 mb-2">
                    How Moonscape Helps
                  </h3>
                  <ul className="list-disc list-inside text-sm text-gray-500 mb-4">
                    <li>
                      Supports wallet-by-wallet tracking to simplify compliance.
                    </li>
                    <li>
                      Assists with transitioning cost basis during the relief
                      period.
                    </li>
                  </ul>
                  <a
                    href="https://moonscape.app/blog/rev-proc-2024-28-spec-identification"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-500 hover:underline"
                  >
                    Read more
                  </a>
                </div>
              </div>

              {/* <div className="bg-white p-6 rounded-lg  min-w-80 ">
                <IndividualInformation
                  data={
                    // Example usage
                    {
                      taxJurisdiction: profile?.taxJurisdiction,
                      currentIncomeTaxRate: getCGTRateForCurrentYear(profile),
                      currentCGTTaxRate: displayCGTRate,

                      baseCurrency: profile?.baseCurrency,
                      costBasisMethod: profile?.accountingMethod,
                      disposals: cgt?.disposals,
                      estimateGain: cgt?.total_gain,
                      proceeds: cgt?.total_proceeds,
                      estimateLoss: cgt?.total_loss,
                      allowance: cgt?.allowance,
                      estimateTax: cgt?.estimated_tax_due,
                    }
                  }
                />
              </div> */}
              <div className="bg-white p-6 rounded-lg  min-w-80 ">
                {profile?.taxJurisdiction == TaxJurisdiction.UK && <FAQsUK />}
                {profile?.taxJurisdiction == TaxJurisdiction.US && <FAQsUS />}
              </div>
            </div>
          </div>

          <Switch>
            <Route path={`${path}/cgt`} exact>
              {/* Tax CGT Page Content */}
            </Route>
            <Route path={`${path}/income`}>
              {/* Tax Income Page Content */}
            </Route>
            <Route path={`${path}/hmrc`}>{/* Tax HMRC Page Content */}</Route>
            <Route path={`${path}/holdings`}>
              {/* Tax Holdings Page Content */}
            </Route>
          </Switch>
        </div>
      </main>
    </Screen>
  );
};
