import React, { Fragment, useState, useEffect } from "react";

import { CoinIcon } from "@/components";
import { Apikeys } from "@/types/moonscape";

export const LinkedExchangeRow = ({ exchange }) => {
  return (
    <li key={exchange?.name} className="py-4 grid grid-cols-8 gap-5">
      <div className="col-span-4 sm:col-span-4 flex min-w-min">
        <span className="inline-block h-8 w-8  rounded-full overflow-hidden bg-gray-100">
          <CoinIcon
            className="h-8 w-8 text-gray-400"
            // viewBox="0 0 10 10"
            // name={"binance"}
            name={exchange.exchangename.toLowerCase()}
          />
        </span>
        <div className="ml-3 flex flex-col">
          <span className="text-sm font-medium text-gray-900">
            {exchange?.exchangename}
          </span>
          <span className="text-sm text-gray-500">{exchange?.email}</span>
        </div>
      </div>

      <div className="col-span-4 sm:col-span-4">
        <div className="px-4 py-3  text-right sm:px-6">
          {/* <button
                    type="button"
                    className="bg-white inline-flex justify-end px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    onClick={() => {
                      console.log("delete connection")
                    }}
                  >
                    <XMarkIconSolid
                      className="-ml-2 mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>Delete</span>
                  </button> */}
        </div>
      </div>
    </li>
  );
};

export const ConnectedExchangeList = ({ exchanges }) => {
  console.log("connected exchanges ", exchanges);
  return (
    <div className="border-b border-gray-200">
      <ul className="divide-y py-4 divide-gray-200">
        {!exchanges?.length && (
          <div>
            <p>connect your first exchange</p>
          </div>
        )}
        {exchanges &&
          exchanges?.length &&
          exchanges?.map((exchange) => (
            <LinkedExchangeRow exchange={exchange} />
          ))}
      </ul>
    </div>
  );
};

interface LinkedExchangeListProps {
  exchanges: Apikeys[] | undefined;
}
export const LinkedExchangeList = ({ exchanges }: LinkedExchangeListProps) => {
  // console.log("linked exchanges", exchanges);
  if (!exchanges) return null;

  const oauth = exchanges?.oauth;
  const api = exchanges?.api;

  const oauthExchanges = oauth?.map(
    (e) => e?.exchangename && `${e.exchangename} OAuth2`
  );
  const apiExchanges = api?.map(
    (e) => e?.exchangename && `${e.exchangename} API`
  );

  const exchangeNamesArr = [...oauthExchanges, ...apiExchanges];

  const linkedExchanges =
    exchangeNamesArr?.length === 1
      ? exchangeNamesArr[0]
      : exchangeNamesArr.join(", ");

  return exchangeNamesArr?.map((exchangename) => (
    <span
      key={exchangename}
      className="inline-block rounded-full bg-rose-50 px-3 py-0.5 mb-2 mr-2 text-sm font-medium text-rose-700 hover:bg-rose-100"
    >
      {exchangename}
    </span>
  ));
};
