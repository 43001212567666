import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { axios, authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Screen } from "@/components";
import { useProfile } from "@moonscape/shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HODLPlanBenefits = ["Add HODL Benefits"];

const BASEPlanBenefits = ["Add BASE benefits"];
/**
 * add new plan benefits here and ensure missing priceIds (for different currencies) are added
 */
const planBenefits = {
  // BASE plan for all currencies
  price_1QI7urAzMb2cPEk8g7wfIz11: [
    // BASE plan - USD
    ...BASEPlanBenefits,
  ],
  price_1QMzuwAzMb2cPEk8wkqxzMqE: [
    // BASE plan - GBP
    ...BASEPlanBenefits,
  ],

  // HODL plan for all currencies
  price_1QI7vTAzMb2cPEk8iayNMpeU: [
    // HODL plan - USD
    ...HODLPlanBenefits,
  ],
  price_1QMzjzAzMb2cPEk8qbJE9VWb: [
    // HODL plan - GBP
    ...HODLPlanBenefits,
  ],
};

export const StripeCheckout = () => {
  const [stripeError, setStripeError] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeSessionId, setStripeSessionId] = useState(null);
  const [tiers, setTiers] = useState([]);

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const userId = user?.email;

  const {
    isQueryLoading: isProfileQueryLoading,
    error: profileQueryError,
    profile: profile, //{ taxJurisdiction, linkedExchangeAccounts, baseCurrency },
  } = useProfile();

  useEffect(() => {
    async function setupStripe() {
      const stripeKey = process.env.REACT_APP_STRIPE_KEY;
      if (!stripeKey) {
        console.log("[checkout] stripe key missing ");
        return;
      }
      let response = await loadStripe(stripeKey);
      setStripe(response);
    }

    if (!stripe) {
      setupStripe();
    }
  }, [stripe]);

  useEffect(() => {
    async function fetchPricing() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/stripe/prices`
        );
        // use profile's tax jurisdiction to filter prices
        const userCurrency = profile?.baseCurrency || "USD"; // Default to USD if user currency is not available
        console.log("profile: currency", profile);
        // const userCurrency = user?.currency || "usd"; // Default to USD if user currency is not available
        const prices = response.data.data
          .filter((price) => price.currency === userCurrency.toLowerCase())
          .map((price) => ({
            name: price.product.name,
            id: price.id,
            priceFormatted: (price.unit_amount / 100).toLocaleString("en-US", {
              style: "currency",
              currency: price.currency.toUpperCase(),
            }),
            interval: price.recurring.interval,
            description: price.product.description,
            features: planBenefits[price.id] || [],
            featured: price.product.metadata.featured === "true",
            unit_amount: price.unit_amount,
          }))
          .sort((a, b) => a.unit_amount - b.unit_amount); // Sort by cost ascending
        setTiers(prices);
      } catch (error) {
        console.error("Error fetching pricing information:", error);
      }
    }

    fetchPricing();
  }, [user]);

  const handleGetAccess = async (priceId, giftId = null) => {
    try {
      const token = await getAccessTokenSilently();
      const endpoint = `/create-checkout-session`;
      const response = await authorizedAxios(token).get(
        `${process.env.REACT_APP_SERVER_URL}${endpoint}`,
        {
          params: {
            planId: priceId,
            giftId: giftId,
          },
        }
      );
      console.log("response from create-checkout-session", response);
      const sessionId = response.data.id;
      setStripeSessionId(sessionId);
      stripe.redirectToCheckout({ sessionId });
    } catch (e) {
      console.log("[checkout] error", e);
      setStripeError(e);
    }
  };

  return (
    <Screen heading="Plans">
      <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          />
        </div>
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base/7 font-semibold text-indigo-600">Pricing</h2>
          <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
            Choose the right plan for you
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 sm:text-xl/8">
          Choose a plan that simplifies your crypto taxes with powerful
          features—track your portfolio, calculate gains, and generate tax
          reports effortlessly. Stay compliant and save time.
        </p>
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured
                  ? "relative bg-gray-900 shadow-2xl"
                  : "bg-white/60 sm:mx-8 lg:mx-0",
                tier.featured
                  ? ""
                  : tierIdx === 0
                  ? "rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none"
                  : "sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl",
                "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? "text-indigo-400" : "text-indigo-600",
                  "text-base/7 font-semibold"
                )}
              >
                {tier.name}
              </h3>
              <p className="mt-4 flex items-baseline gap-x-2">
                <span
                  className={classNames(
                    tier.featured ? "text-white" : "text-gray-900",
                    "text-5xl font-semibold tracking-tight"
                  )}
                >
                  {tier.priceFormatted}
                </span>
                <span
                  className={classNames(
                    tier.featured ? "text-gray-400" : "text-gray-500",
                    "text-base"
                  )}
                >
                  /{tier.interval}
                </span>
              </p>
              <p
                className={classNames(
                  tier.featured ? "text-gray-300" : "text-gray-600",
                  "mt-6 text-base/7"
                )}
              >
                {tier.description}
              </p>
              <ul
                role="list"
                className={classNames(
                  tier.featured ? "text-gray-300" : "text-gray-600",
                  "mt-8 space-y-3 text-sm/6 sm:mt-10"
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      aria-hidden="true"
                      className={classNames(
                        tier.featured ? "text-indigo-400" : "text-indigo-600",
                        "h-6 w-5 flex-none"
                      )}
                    />
                    {feature}
                  </li>
                ))}
              </ul>
              <button
                onClick={() => handleGetAccess(tier.id)}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? "bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500"
                    : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600",
                  "mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
                )}
              >
                Get started today
              </button>
            </div>
          ))}
        </div>
      </div>
    </Screen>
  );
};
