import {
  UseQueryResult,
  useQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth, toast } from "@moonscape/shared";
import { minutes } from "@/utils";

interface SafeHarbor {
  id: string;
  year: number;
  status: string;
  details: SafeHarborDetail[];
}

interface SafeHarborDetail {
  id: string;
  description: string;
  amount: number;
}

export const useSafeHarborQueryKey = ["safeHarbor"];

export const useSafeHarbor = (year: number) => {
  const { user, isAuthenticated, isLoading, getToken } = useAuth();
  const email = user?.email;
  const queryClient = useQueryClient();

  const getSafeHarbor = async (): Promise<SafeHarbor> => {
    const url = `/safe-harbor/${year}`;
    try {
      const token = await getToken();
      const res = await authorizedAxios(token).get(url);
      return res.data;
    } catch (error) {
      console.log("[useSafeHarbor] error", error);
      throw error;
    }
  };

  const {
    isLoading: isQueryLoading,
    isIdle,
    isSuccess,
    error,
    isError,
    data: safeHarbor,
    refetch,
  }: UseQueryResult<SafeHarbor, unknown> = useQuery({
    queryKey: [useSafeHarborQueryKey, year],
    queryFn: getSafeHarbor,
    enabled: !!email,
    refetchOnWindowFocus: true,
    staleTime: 1 * minutes,
    refetchInterval: 5 * minutes,
  });

  const storeSafeHarbor = async (data: Partial<SafeHarbor>) => {
    const token = await getToken();
    const url = `/safe-harbor`;
    const response = await authorizedAxios(token).post(url, data);
    return response.data;
  };

  const updateSafeHarbor = async (id: string, data: Partial<SafeHarbor>) => {
    const token = await getToken();
    const url = `/safe-harbor/${id}`;
    const response = await authorizedAxios(token).post(url, data);
    return response.data;
  };

  const storeSafeHarborDetail = async (
    id: string,
    data: Partial<SafeHarborDetail>
  ) => {
    const token = await getToken();
    const url = `/safe-harbor/${id}/safe-harbor-details`;
    const response = await authorizedAxios(token).post(url, data);
    return response.data;
  };

  const updateSafeHarborDetail = async (
    id: string,
    detailId: string,
    data: Partial<SafeHarborDetail>
  ) => {
    const token = await getToken();
    const url = `/safe-harbor/${id}/safe-harbor-details/${detailId}`;
    const response = await authorizedAxios(token).post(url, data);
    return response.data;
  };

  const storeMutation = useMutation({
    mutationFn: storeSafeHarbor,
    onMutate: async () => {
      toast.success("Storing Safe Harbor...");
    },
    onSuccess: () => {
      queryClient.invalidateQueries([useSafeHarborQueryKey, year]);
      toast.success("Safe Harbor stored successfully");
    },
    onError: (error) => {
      console.log("[storeSafeHarbor] error", error);
      toast.error("Error storing Safe Harbor");
    },
  });

  const updateMutation = useMutation({
    mutationFn: updateSafeHarbor,
    onMutate: async () => {
      toast.success("Updating Safe Harbor...");
    },
    onSuccess: () => {
      queryClient.invalidateQueries([useSafeHarborQueryKey, year]);
      toast.success("Safe Harbor updated successfully");
    },
    onError: (error) => {
      console.log("[updateSafeHarbor] error", error);
      toast.error("Error updating Safe Harbor");
    },
  });

  const storeDetailMutation = useMutation({
    mutationFn: storeSafeHarborDetail,
    onMutate: async () => {
      toast.success("Storing Safe Harbor detail...");
    },
    onSuccess: () => {
      queryClient.invalidateQueries([useSafeHarborQueryKey, year]);
      toast.success("Safe Harbor detail stored successfully");
    },
    onError: (error) => {
      console.log("[storeSafeHarborDetail] error", error);
      toast.error("Error storing Safe Harbor detail");
    },
  });

  const updateDetailMutation = useMutation({
    mutationFn: updateSafeHarborDetail,
    onMutate: async () => {
      toast.success("Updating Safe Harbor detail...");
    },
    onSuccess: () => {
      queryClient.invalidateQueries([useSafeHarborQueryKey, year]);
      toast.success("Safe Harbor detail updated successfully");
    },
    onError: (error) => {
      console.log("[updateSafeHarborDetail] error", error);
      toast.error("Error updating Safe Harbor detail");
    },
  });

  return {
    isQueryLoading,
    isIdle,
    error,
    safeHarbor,
    email,
    refetch,
    storeMutation,
    updateMutation,
    storeDetailMutation,
    updateDetailMutation,
  };
};
