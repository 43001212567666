import React, { useContext, useEffect, useState } from "react";

import { OnboardingModal, TradesScreen } from "@/screens";

import { useProfile } from "@moonscape/shared";
import { Loading } from "@/components/Loading";

export const LandingScreen = () => {
  const { profile, isQueryLoading } = useProfile();
  const isOnboardingCompleted = profile?.attributes?.onboarding_completed;
  const [shouldShowOnboarding, setShouldShowOnboarding] = useState(false);

  useEffect(() => {
    const checkOnboardingTimestamp = () => {
      try {
        const lastOnboardingCheck = localStorage.getItem("lastOnboardingCheck");
        const currentTime = Date.now();

        if (lastOnboardingCheck) {
          const lastCheckTime = parseInt(lastOnboardingCheck, 10);
          const hoursDiff = (currentTime - lastCheckTime) / (1000 * 60 * 60);

          // If less than 48 hours have passed, don't show onboarding
          if (hoursDiff < 48) {
            setShouldShowOnboarding(false);
            return;
          }
        }

        // Show onboarding if needed and update timestamp
        if (isOnboardingCompleted === false) {
          localStorage.setItem("lastOnboardingCheck", currentTime.toString());
          setShouldShowOnboarding(true);
        }
      } catch (error) {
        console.error("Error checking onboarding timestamp:", error);
      }
    };

    checkOnboardingTimestamp();
  }, [isOnboardingCompleted]);

  if (isQueryLoading) {
    return <Loading />;
  }

  return (
    <>
      <TradesScreen />
      {shouldShowOnboarding && <OnboardingModal />}
    </>
  );
};
