import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { authorizedAxios } from "@/axios";
import { useAuth0 } from "@auth0/auth0-react";
import { ButtonLink } from "../Button";

export const StripeSuccess = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const userId = user?.email;
  const location = useLocation();
  const values = queryString.parse(location?.search);
  const session_id = values.session_id;

  useEffect(() => {
    async function getStripeOrderDetails() {
      try {
        const token = await getAccessTokenSilently();
        const res = await authorizedAxios(token).get(
          `order/success/${userId}?session_id=${session_id}`
        );
        console.log("res.data", res.data);
      } catch (e) {
        console.log("error getStripeOrderDetails", e);
      }
    }
    if (session_id) getStripeOrderDetails();
  }, [getAccessTokenSilently, userId, session_id]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white mt-12 shadow-lg rounded-lg">
        <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              You're upgraded 🎉
            </h2>
            <p className="mt-4 text-lg font-medium text-gray-500 sm:text-xl">
              Thank you for upgrading your plan.
            </p>
            <div className="mt-6 flex justify-center">
              <ButtonLink href="/" buttonText="Go to Dashboard" />
            </div>
          </div>
          <div className="mt-10 flex justify-center">
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline">
                {" "}
                Your subscription has been activated.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const StripeCancel = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white mt-12 shadow-lg rounded-lg">
        <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Checkout Cancelled
            </h2>
            <p className="mt-4 text-lg font-medium text-gray-500 sm:text-xl">
              Your checkout process was cancelled. Please try again.
            </p>
            <div className="mt-6 flex justify-center">
              <ButtonLink href="/" buttonText="Go to Dashboard" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
