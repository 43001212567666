import { useQuery, useQueryClient } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth } from "@moonscape/shared";
import { API } from "../config/api-endpoints";

interface FeatureFlag {
  key: string;
  description: string | null;
  enabled: boolean;
  rules: any | null;
}

interface FeatureFlagsResponse {
  error: boolean;
  status: number;
  message: string;
  result: FeatureFlag[];
}

// Cache key for feature flags
export const FEATURE_FLAGS_QUERY_KEY = "feature-flags";

// Hook to fetch all feature flags
export const useFeatureFlags = () => {
  const { getToken, isAuthenticated } = useAuth();

  return useQuery({
    queryKey: [FEATURE_FLAGS_QUERY_KEY],
    queryFn: async () => {
      const token = await getToken();
      const response = await authorizedAxios(token).get<FeatureFlagsResponse>(
        API.featureFlags
      );
      // console.log("flags response", response.data);
      return response.data.result;
    },
    enabled: isAuthenticated,
    // Cache for 5 minutes
    staleTime: 5 * 60 * 1000,
  });
};

// Hook to check a specific feature flag
export const useFeatureFlag = (key: string) => {
  const { data: flags, ...rest } = useFeatureFlags();
  // console.log("useFeatureFlag flags", flags);
  const flag = flags?.find((f) => f.key === key);

  return flag?.enabled ?? false;
};

// Helper function to check if a feature is enabled from the cache
export const isFeatureEnabled = (key: string): boolean => {
  const queryClient = useQueryClient();
  const flags = queryClient.getQueryData<FeatureFlag[]>([
    FEATURE_FLAGS_QUERY_KEY,
  ]);
  const flag = flags?.find((f) => f.key === key);
  return flag?.enabled ?? false;
};
